@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: GothamBold;
  src: url("./assets/Gotham-Font/Gotham-Bold.otf");
  /* src: url("./assets/Gotham-Font/Gotham-Bold.otf"); */
}
@font-face {
  font-family: GothamLight;
  src: url("./assets/Gotham-Font/Gotham-Light.otf");
}
@font-face {
  font-family: GothamBlack;
  src: url("./assets/Gotham-Font/Gotham-Black.otf");
}
@font-face {
  font-family: GothamThin;
  src: url("./assets/Gotham-Font/Gotham-Thin.otf");
}
@font-face {
  font-family: GothamXLight;
  src: url("./assets/Gotham-Font/Gotham-XLight.otf");
}

/* src: url('./assets/Gotham-Font/Gotham-Bold.otf');
src: url('./assets/Gotham-Font/Gotham-Light.otf');
src: url('./assets/Gotham-Font/Gotham-Black.otf');
src: url('./assets/Gotham-Font/Gotham-Thin.otf');
src: url('./assets/Gotham-Font/Gotham-XLight.otf'); */

* {
  margin: 0;
  padding: 0;
}
